var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "app flex-row align-items-center"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('b-row', {
    staticClass: "justify-content-center"
  }, [_c('b-col', {
    attrs: {
      "md": "6",
      "sm": "8"
    }
  }, [_c('b-card', {
    staticClass: "mx-4",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-body', {
    staticClass: "p-4"
  }, [_c('b-form', [_c('h1', [_vm._v("Register")]), _c('p', {
    staticClass: "text-muted"
  }, [_vm._v("Create your account")]), _c('b-input-group', {
    staticClass: "mb-3"
  }, [_c('b-input-group-prepend', [_c('b-input-group-text', [_c('i', {
    staticClass: "icon-user"
  })])], 1), _c('b-form-input', {
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "placeholder": "Username",
      "autocomplete": "username"
    }
  })], 1), _c('b-input-group', {
    staticClass: "mb-3"
  }, [_c('b-input-group-prepend', [_c('b-input-group-text', [_vm._v("@")])], 1), _c('b-form-input', {
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "placeholder": "Email",
      "autocomplete": "email"
    }
  })], 1), _c('b-input-group', {
    staticClass: "mb-3"
  }, [_c('b-input-group-prepend', [_c('b-input-group-text', [_c('i', {
    staticClass: "icon-lock"
  })])], 1), _c('b-form-input', {
    staticClass: "form-control",
    attrs: {
      "type": "password",
      "placeholder": "Password",
      "autocomplete": "new-password"
    }
  })], 1), _c('b-input-group', {
    staticClass: "mb-4"
  }, [_c('b-input-group-prepend', [_c('b-input-group-text', [_c('i', {
    staticClass: "icon-lock"
  })])], 1), _c('b-form-input', {
    staticClass: "form-control",
    attrs: {
      "type": "password",
      "placeholder": "Repeat password",
      "autocomplete": "new-password"
    }
  })], 1), _c('b-button', {
    attrs: {
      "variant": "success",
      "block": ""
    }
  }, [_vm._v("Create Account")])], 1)], 1), _c('b-card-footer', {
    staticClass: "p-4"
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-button', {
    staticClass: "btn btn-facebook",
    attrs: {
      "block": ""
    }
  }, [_c('span', [_vm._v("facebook")])])], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-button', {
    staticClass: "btn btn-twitter",
    attrs: {
      "block": "",
      "type": "button"
    }
  }, [_c('span', [_vm._v("twitter")])])], 1)], 1)], 1)], 1)], 1)], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }